<template>
  <v-container fluid>
    <div v-if="!$db.userAdmin || !$db.userAdmin.canManageForms">

      <div class="d-flex justify-center align-center" style="min-height: 80vh">
        <div class="mx-auto">
          <v-alert
              v-if="$db.userAdmin && !$db.userAdmin.canManageForms"
              class="my-5"
              color="error"
              elevation="5"
              dark dense>
            Vous n'avez pas l'autorisation de gérer les formulaires
          </v-alert>
          <login-box
              class="flex-shrink-0"
              @login="$utils.navigation.reload()"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <v-row class="py-5">
        <v-col cols="3">
          <v-select
              hide-details
              label="Formulaire"
              rounded filled
              v-model="type"
              @change="selected=[]"
              :items="[
                {
                  text:'Contact',
                  value:'formcontact'
                },
                {
                  text:'Demandes de brochures',
                  value:'formbrochure'
                },
                {
                  text:'Inscriptions',
                  value:'forminscription'
                }
                ]"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
              v-model="search"
              rounded filled
              :append-icon="$icons.search"
              label="Rechercher"
              clearable
              single-line
              hide-details
          />
        </v-col>
        <v-col cols="3" class=" align-center d-flex">
          <span class="mr-5 primary--text subtitle-2">{{groupActionLabel}}</span>
          <div v-if="selected.length>0">
            <v-btn icon small @click="trashSelection" class="mr-2">
              <v-icon>{{$icons.trash}}</v-icon>
            </v-btn>
            <v-btn icon small @click="csvSelection">
              <v-icon>{{$icons.fileExcel}}</v-icon>
            </v-btn>
          </div>

        </v-col>
      </v-row>
      <v-data-table
          show-select checkbox-color="primary"
          v-model="selected"
          :search="search"
          :items="lines"
          :headers="headers"
          :options="{
            'sortBy':['date'],
            'sortDesc':[true],
          }"
          sort-by=""
          sort-desc
          disable-pagination
          hide-default-footer
      >
        <template v-slot:item.date="{ item }">
          <table-field-date :date-field="item.date"/>
        </template>

        <template v-slot:item.ville="{ item }">
          {{item.ville}} <br>
          {{item.cp}} - {{item.pays}}
        </template>

        <template v-slot:item.message="{ item }">
          <div style="max-width: 500px" class="py-2">
            {{item.message}}
          </div>
        </template>


        <template v-slot:item.actions="{ item }">
          <v-btn icon small @click="trash(item)">
            <v-icon>{{$icons.trash}}</v-icon>
          </v-btn>
        </template>

      </v-data-table>
    </div>
  </v-container>



</template>

<script>


import DateField from "@/ee09/fields/DateField";
import TableFieldDate from "@/ee09/db-ui/table-fields/table-field-date";
import LoginBox from "@/ee09/login/login-box";
//export exportFromJSON from 'export-from-json'

export default {
  name: "formulaires",
  components: {LoginBox, TableFieldDate},
  data(){
    return{
      type:"formcontact",
      records:[],
      selected:[],
      search:""
    }
  },
  mounted(){
    this.$layout.currentPage.uid=null;
    if(this.$db.userAdmin){
      this.load();
    }

  },
  computed:{
    lines(){
      return this.records.filter(item=>item.type===this.type)
    },
    groupActionLabel(){
      let r="Aucune sélection";
      if(this.selected.length===1){
        r="Pour cette ligne";
      }else if(this.selected.length>1){
        r="Pour ces "+this.selected.length+" lignes"
      }
      return r;
    },
    headers(){
      let h=[];
      h.push(
          {
            text: 'Date',
            value: 'date' ,
            sort: (a, b) => a.date.getTime()-b.date.getTime()
          }
      );
      h.push(
        {
          text: 'Nom',
          align: 'start',
          sortable: true,
          value: 'nom',
        }
      );

      h.push(
          {
            text: 'Prénom',
            value: 'prenom'
          }
      );
      h.push(
          {
            text: 'Email',
            value: 'email'
          }
      );
      if(this.type==="formcontact"){
        h.push(
            {
              text: 'Objet',
              value: 'objet'
            }
        );
        h.push(
            {
              text: 'Message',
              value: 'message'
            }
        );
      }
      if(this.type==="formbrochure"){
        h.push(
            {
              text: 'Adresse',
              value: 'adresse'
            }
        );
        h.push(
            {
              text: 'Ville',
              value: 'ville'
            }
        );
        h.push(
            {
              text: 'Téléphone',
              value: 'tel'
            }
        );
      }

      if(this.type==="forminscription"){
        h.push(
            {
              text: 'Formation',
              value: 'formation'
            }
        );
        h.push(
            {
              text: 'Adresse',
              value: 'adresse'
            }
        );
        h.push(
            {
              text: 'Ville',
              value: 'ville'
            }
        );
        h.push(
            {
              text: 'Téléphone',
              value: 'tel'
            }
        );
      }

      h.push(
          {
            text: '...',
            value: 'actions',
            sortable:false,
            align:"end"
          }
      );






      return h;
    },
    selectedLinesPlural(){
      if(this.selected.length===1){
        return "cette ligne";
      }
      if(this.selected.length>1){
        return "ces "+this.selected.length+" lignes";
      }
      return "aucune ligne"
    }
  },
  methods: {
    load(){
      let me=this;
      this.$api.getListRecords(["formcontact","formbrochure","forminscription"],function(data){
        console.log(data)
        me.records=data.body.records;
        for(let r of me.records){
          r.date=new DateField()
          r.date.isoString=r.date_created.isoString;
        }
      })
    },
    trash(line,askConfirm=true){
      if(askConfirm){
        if(confirm("Êtes-vous sur de vouloir supprimer cette ligne définitivement ?")){
          this.trash(line,false)
        }
      }else{
        console.log("trash",line)
        let me=this;
        window.$api.setTrashRecord(line,function(){
          me.load();
        })
      }

    },
    trashSelection(){
      if(confirm("Êtes-vous sur de vouloir supprimer "+this.selectedLinesPlural+" définitivement ?")){
        for(let line of this.selected){
          this.trash(line,false);
        }
      }

    },
    csvSelection(){
      console.log("csv")
      let lines=[];
      for(let rec of this.selected){
        lines.push(this.csvObject(rec))
      }
      console.log(lines);
      this.$utils.navigation.openBlankWithPost(
          this.$api.xls(),lines
      )
    },
    /**
     *
     * @param {DbRecord} line
     */
    csvObject(line){
      let r={};
      r.Date=line.date.format('dd-MM-yyyy')
      r.Nom=line.nom;
      r["Prénom"]=line.prenom;
      r["Email"]=line.email;
      if(line.type==="formcontact"){
        r["Objet"]=line.objet;
        r["Message"]=line.message;
      }
      if(line.type==="formbrochure" ){
        r["Téléphone"]=line.tel;
        r["Adresse"]=line.adresse;
        r["Ville"]=line.ville;
        r["Code postal"]=line.cp;
        r["Pays"]=line.pays;
      }
      if(line.type==="forminscription"){
        r["Formation"]=line.formation;
        r["Téléphone"]=line.tel;
        r["Adresse"]=line.adresse;
        r["Ville"]=line.ville;
        r["Code postal"]=line.cp;
        r["Pays"]=line.pays;
      }
      return JSON.parse(JSON.stringify(r));
    }


  }
}
</script>
