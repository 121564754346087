var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(!_vm.$db.userAdmin || !_vm.$db.userAdmin.canManageForms)?_c('div',[_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"min-height":"80vh"}},[_c('div',{staticClass:"mx-auto"},[(_vm.$db.userAdmin && !_vm.$db.userAdmin.canManageForms)?_c('v-alert',{staticClass:"my-5",attrs:{"color":"error","elevation":"5","dark":"","dense":""}},[_vm._v(" Vous n'avez pas l'autorisation de gérer les formulaires ")]):_vm._e(),_c('login-box',{staticClass:"flex-shrink-0",on:{"login":function($event){return _vm.$utils.navigation.reload()}}})],1)])]):_c('div',[_c('v-row',{staticClass:"py-5"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"hide-details":"","label":"Formulaire","rounded":"","filled":"","items":[
              {
                text:'Contact',
                value:'formcontact'
              },
              {
                text:'Demandes de brochures',
                value:'formbrochure'
              },
              {
                text:'Inscriptions',
                value:'forminscription'
              }
              ]},on:{"change":function($event){_vm.selected=[]}},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"rounded":"","filled":"","append-icon":_vm.$icons.search,"label":"Rechercher","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:" align-center d-flex",attrs:{"cols":"3"}},[_c('span',{staticClass:"mr-5 primary--text subtitle-2"},[_vm._v(_vm._s(_vm.groupActionLabel))]),(_vm.selected.length>0)?_c('div',[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","small":""},on:{"click":_vm.trashSelection}},[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.trash))])],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.csvSelection}},[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.fileExcel))])],1)],1):_vm._e()])],1),_c('v-data-table',{attrs:{"show-select":"","checkbox-color":"primary","search":_vm.search,"items":_vm.lines,"headers":_vm.headers,"options":{
          'sortBy':['date'],
          'sortDesc':[true],
        },"sort-by":"","sort-desc":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
        var item = ref.item;
return [_c('table-field-date',{attrs:{"date-field":item.date}})]}},{key:"item.ville",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.ville)+" "),_c('br'),_vm._v(" "+_vm._s(item.cp)+" - "+_vm._s(item.pays)+" ")]}},{key:"item.message",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"py-2",staticStyle:{"max-width":"500px"}},[_vm._v(" "+_vm._s(item.message)+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.trash(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.trash))])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }